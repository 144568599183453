import React, { useRef, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import offer from '../Assets/new/5.jpg'
import ai from '../Assets/new/ai.svg'
import { Link } from 'react-router-dom';
import offer2 from '../Assets/new/san.jpg'
import cln from '../Assets/new/cln.png'
import offer3 from '../Assets/new/coss.png'
import dis from '../Assets/new/dis.png'
import sta from '../Assets/new/sta.png'
import elctronic from '../Assets/new/elec.png'
import saf from '../Assets/new/saf.png'
import dec from '../Assets/new/dec.png'
import plants from '../Assets/new/plants.png'
import home from '../Assets/new/627.png'
import productbanner from '../Assets/new/banners.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Products = () => {


	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
	useEffect(() => {
		AOS.init({
			duration: 2000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);

	return (
		<div className='about-page'>
			<div className="product-banner">
				<img src={productbanner} alt="" />
			</div>
			<div className="producta-items-main">
				<div className="container-main">
					<div className="row">
						
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>Cosmetics</h3>
								<p className='item-subb'>Our cosmetics line features products that are not only luxurious but also safe and
									effective. From skincare essentials to beauty enhancers, we prioritize ingredients that
									nourish and protect, catering to a wide range of skin types and preferences.</p>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Premium Quality</h5>
									<p>We offer top-quality skincare and beauty products.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Safe Ingredients</h5>
									<p>All products are formulated with safe, effective ingredients</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Wide Variety										</h5>
									<p>Our range includes makeup, skincare, and personal care items</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> For All Skin Types</h5>
									<p>Products are suitable for various skin types and needs</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Trendy and Innovative</h5>
									<p>We stay updated with the latest beauty trends and innovations</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Eco-friendly Options</h5>
									<p>We provide environmentally conscious beauty products.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={offer3} className='product-page-img' alt="" />
							</div>
						</div>
						{/* ====================================================== */}
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={home} className='product-page-img' alt="" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>
									Home Appliances  & Electronics</h3>
								<p className='item-subb'>At AGI, we provide an extensive range of electronics and home appliances, including the
									latest in smart technology and energy-efficient devices. From home entertainment
									systems to essential kitchen appliances, our products are designed to meet modern
									household needs with reliability and style.</p>
									<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Smart Technology</h5>
									<p>Our products feature the latest in smart technology, bringing convenience and connectivity to your home.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Energy Efficient</h5>
									<p>Designed with energy-saving features to reduce costs and support a greener environment</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Comprehensive Range									</h5>
									<p>From home entertainment systems to essential kitchen appliances, we cover all your household needs.</p>
								</div>
							</div>
						</div>
						{/* ====================================================== */}
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>IT & Mobile
									Accessories</h3>
								<p className='item-subb'>Your go-to destination for premium IT solutions and cutting-edge
									mobile accessories. We provide a wide range of products, from
									advanced tech gadgets to essential mobile gear, ensuring seamless
									connectivity and style. Elevate your tech experience with quality,
									innovation, and unmatched service.</p>
									<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> High-Quality Tech Gear								</h5>
									<p>From advanced gadgets to essential accessories, our products are built to last and perform.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Seamless Connectivity
									</h5>
									<p>Designed to enhance connectivity and ensure smooth integration with your devices.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Wide Selection									</h5>
									<p>Choose from a diverse range of accessories, including chargers, cables, cases, and more.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={elctronic} className='product-page-img' alt="" />
							</div>
						</div>
						{/* ====================================================== */}
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={plants} className='product-page-img' alt="" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>Indoor/
									Outdoor
									Plants</h3>
								<p className='item-subb'>Bring life to your spaces with our selection of indoor and outdoor plants. We offer a variety of plants
									that are easy to maintain and ideal for homes, offices, and gardens. Our plants are chosen for their
									beauty, resilience, and ability to enliven any environment.</p>
									<div className="items-single">
										<h5 className='single-text'><i class="ri-arrow-right-line"></i>Variety of Choices									</h5>
										<p>Explore a wide selection of plants perfect for homes, offices, and gardens.</p>
								</div>
								<div className="items-single">
										<h5 className='single-text'><i class="ri-arrow-right-line"></i>Easy to Maintain										</h5>
										<p>Our plants are low-maintenance, making them suitable for any lifestyle.</p>
								</div>
							</div>
						</div>
						{/* ======================================= */}
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>Disposable</h3>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Quality and Durability									</h5>
									<p>Even our single-use products
										are designed for reliability
										and high quality</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Eco-friendly Options</h5>
									<p>We offer biodegradable and
										recyclable disposables to reduce
										environmental impac and
										sustainable future</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Hygiene and Convenience								</h5>
									<p>Our disposable items, from cups to
										gloves, ensure utmost cleanliness.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={dis} className='product-page-img' alt="" />
							</div>
						</div>
						{/* ======================================= */}
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={sta} className='product-page-img' alt="" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>Stationery</h3>
								<p className='item-subb'>Stationery refers to writing materials and office supplies. It encompasses a wide range of items used for writing, drawing, organizing, and other related tasks.</p>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i> Comprehensive Range	</h5>
									<p>We offer a wide selection of stationary
										products, including pens, notebooks, paper,
										and office supplies.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Quality Materials</h5>
									<p>Our stationary items are made from
										high-quality materials, ensuring durability
										and reliability for everyday use.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Innovative Designs							</h5>
									<p>With a focus on both functionality and
										style, our stationery products feature
										modern designs to suit personal and
										professional needs.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Eco-friendly Options				</h5>
									<p>We provide eco-conscious stationery
										options made from recycled materials
										to support sustainability efforts.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>The Evolution of Stationery	</h5>
									<p>From quill pens and inkwells to modern-day digital tools, stationery has evolved significantly over time. While traditional stationery items continue to be popular, digital tools like e-readers, tablets, and smartphones have also become essential for many.</p>
								</div>
							</div>
						</div>
						{/* ====================================================== */}
						
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>Home Decor</h3>
								<p className='item-subb'>Enhance your living spaces with our curated selection of home décor
									items. Whether you’re looking for modern, minimalist, or classic designs,
									our range includes everything from furniture and lighting to decorative
									pieces that add charm and sophistication to any room.</p>
									<div className="items-single">
										<h5 className='single-text'><i class="ri-arrow-right-line"></i>Furniture								</h5>
										<p>Sofa: A centerpiece of the living room, sofas come in various styles and sizes to suit different spaces and needs. Consider factors like comfort, durability, and aesthetics when choosing a sofa.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={dec} className='product-page-img' alt="" />
							</div>
						</div>
						{/* ====================================================== */}

						
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={saf} className='product-page-img' alt="" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>Safety and Uniform								</h3>
								<p className='item-subb'>Our safety equipment and uniforms offer high-quality protection, ensuring durability and comfort in
									hazardous work environments. Designed to meet industry standards, they enhance safety, visibility,
									and overall workforce efficiency</p>
									<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Comfort and Mobility				</h5>
									<p>Designed to provide maximum comfort and freedom of movement.</p>
								</div>
							</div>
						</div>
						
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<h3 className='item-namee'>Cleaning	Material &	Equipments</h3>
								<p className='item-subb'>Cleaning products are designed to
									provide effective solutions for both
									home and industrial environments.
									From eco-friendly cleaning agents
									to heavy-duty industrial cleaners,
									we ensure that our range meets the
									highest standards of hygiene and
									safety</p>
									<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Safety First			</h5>
									<p>Our products are formulated to be safe for users and the environment.</p>
								</div>
								<div className="items-single">
									<h5 className='single-text'><i class="ri-arrow-right-line"></i>Quality Assurance			</h5>
									<p>We source our products from reputable manufacturers to ensure quality and performance.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="inner-product-dv">
								<img src={cln} className='product-page-img' alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>







			{/* <div className="products-page-div" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer2} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>Cleaning Supplies								</h3>
										<p className='item-subb'>Keep your space spotless with our powerful cleaning supplies</p>
										<Link to='/' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer3} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>Cosmetics Items									</h3>
										<p className='item-subb'>Indulge in our premium cosmetics range, featuring skincare essentials</p>
										<Link to='/' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>Home Appliences									</h3>
										<p className='item-subb'>Upgrade your home with our reliable and modern appliances</p>
										<Link to='/' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={elctronic} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>Electronics Items									</h3>
										<p className='item-subb'>Explore our selection of cutting-edge electronics</p>
										<Link to='/' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={st} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>Stationary									</h3>
										<p className='item-subb'>Discover our range of stylish and functional stationery essentials</p>
										<Link to='/' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={rc} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>Food Items									</h3>
										<p className='item-subb'>Homestyle recipes with a gourmet touch.</p>
										<Link to='/' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div className="exclusive">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="exclusive-box">
								<div className="exclusv-half">
									<h2 className='exclusive-text'>Exclusive</h2>
									<p className='exclusive-sub-text'>Get exclusive access to special deals, early product launches, and rewards every time you
										shop with us. Join our loyalty program today and start saving on your favorite products!</p>
								</div>
								<div className="exclusv-icon">
									<img src={ai} className='img-ai' alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Products