import React, { useState } from 'react';
import './Outlet.css';
import { Link } from 'react-router-dom'
import logo from '../Assets/new/fav (1).svg'
const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleCart = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };



  return (
    <div className="navbar-main">
      <div className='container-main'>
        <div className="navbar-home">
          <div className="logo-nav">
            <Link to=''><img src={logo} className='logo-img' alt="Logo" /></Link>
          </div>
          <div className="items-nav">
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/about'>About</Link></li>
              <li><Link to='/products'>Products</Link></li>
              <li><Link to='/services'>Services</Link></li>
              {/* <li><Link to='/customers'>Customers</Link></li> */}
              <li><Link to='/contact'>Contact</Link></li>
            </ul>
          </div>
          <i class="ri-menu-line menu-icon"  onClick={toggleCart}></i>
        </div>
      </div>
      
      <div className={`sidebar ${isSidebarOpen ? 'open-cart' : ''}`}>
        <div className="navbar-sidebar">
          <ul>
          <li><Link to=''>Home</Link></li>
              <li><Link to='/about' onClick={toggleCart}>About</Link></li>
              <li><Link to='/products' onClick={toggleCart}>Products</Link></li>
              <li><Link to='/services' onClick={toggleCart}>Services</Link></li>
              <li><Link to='/contact' onClick={toggleCart}>Contact</Link></li>
          </ul>
        </div>
        <div className="d-inline closse" onClick={toggleCart}>
          <i class="ri-close-large-line close-icon"></i>
        </div>
      </div>
    </div>
  )
}

export default Navbar;