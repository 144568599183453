import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import call from '../Assets/new/call.png'
import location from '../Assets/new/pin (1).png'
import mail from '../Assets/new/email.png'

const Contact = () => {

	useEffect(() => {
		AOS.init({
			duration: 2000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);

	return (
		<div className='contact-page'>
			<div className="common-banner d">
				<div className="container-main">
					<h1 className='banner-title'>Contact Us</h1>
					<p className='sub-title-banner'>Empowering businesses with tailored solutions to drive growth</p>
				</div>
			</div>
			<div className="contact-paged">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="contact-address-box" data-aos="fade-right">
								<div className="mail-box">
									<div className="icon-img-contact">
										<img src={location} className='contactimg' alt="" />
									</div>
									<div className="dettls">
										<h4 className='text-locate'>PO Box 32628, Al Mafraq, Abudhabi, UAE</h4>
									</div>
								</div>
								<div className="mail-box">
									<div className="icon-img-contact">
										<img src={mail} className='contactimg' alt="" />
									</div>
									<div className="dettls">
										<h4 className='text-locate'> info@agi.ae</h4>
									</div>
								</div>
								<div className="mail-box">
									<div className="icon-img-contact">
										<img src={call} className='contactimg' alt="" />
									</div>
									<div className="dettls">
										<h4 className='text-locate'>+971 26731600</h4>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="contact-box-form" data-aos="fade-left">
								<div className="main-box-contact">
									<div className="contact-main">
										<form>
											<div className="form-group">
												<label for="exampleInputEmail1">Name</label>
												<input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" />
											</div>
											<div className="form-group">
												<label for="exampleInputEmail1">Email address</label>
												<input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
											</div>
											<div className="form-group">
												<label for="exampleInputEmail1">Phone Number</label>
												<input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Number" />
											</div>
											<div className="form-group">
												<label for="exampleFormControlTextarea1">Message</label>
												<textarea className="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
											</div>
											<button type='submit' className='btn submit-btn'>Submit Now</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-main">
				<div className="iframe-dv">
					<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d116336.1631702298!2d54.591257!3d24.328892!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e3786c0ff74f9%3A0xb54f6d138770b803!2sSheikh%20Shakhbout%20Medical%20City%20(SSMC)!5e0!3m2!1sen!2sus!4v1728983515125!5m2!1sen!2sus" width="100" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>


		</div>
	)
}

export default Contact;