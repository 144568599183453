import React, { useRef, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import banner from '../Assets/banner.svg'
import about from '../Assets/new/about.svg'
import love from '../Assets/new/love.png'
import leave from '../Assets/new/leaf.png'
import qul from '../Assets/new/quality.png'
import ai from '../Assets/new/ai.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import val from '../Assets/new/7.jpg'
import offer from '../Assets/new/5.jpg'
import offer2 from '../Assets/new/6.jpg'
import offer3 from '../Assets/new/4.jpg'
import pdf from '../Assets/arabian-greens-brochure.pdf'



const Home = () => {

	var settingsBanner = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
	var settingsClients = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};


	useEffect(() => {
		AOS.init({
			duration: 2000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);

	return (
		<div className="home-page">
			<Slider  {...settingsBanner} className=''>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Arabian Greens International (AGI) is mainly operating in General trading, Building Materials, provision of IT and, Technical services, Facility management and much more.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Arabian Greens International (AGI) is mainly operating in General trading, Building Materials, provision of IT and, Technical services, Facility management and much more.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Arabian Greens International (AGI) is mainly operating in General trading, Building Materials, provision of IT and, Technical services, Facility management and much more.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Arabian Greens International (AGI) is mainly operating in General trading, Building Materials, provision of IT and, Technical services, Facility management and much more.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Arabian Greens International (AGI) is mainly operating in General trading, Building Materials, provision of IT and, Technical services, Facility management and much more.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
			</Slider>
			<div className="marquee">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="moving-text">
								<marquee width="100%" direction="left" height="100%" behavior="scroll" scrollamount="3">
									<p ><span className='text-moving'>Fresh Groceries</span><span className='text-moving'>Home Appliances & Electronics</span><span className='text-moving'>Cleaning Supplies</span><span className='text-moving'>Beauty & Cosmetics</span><span className='text-moving'>Office & School Stationery</span></p>
								</marquee>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="aboutus-section">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box btmm" data-aos="fade-right">
								<img src={about} className='about-image' alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box" data-aos="fade-left">
								<h4 className='title-about-us'>About Us</h4>
								<h2 className='title-about-us-main'>Who We Are</h2>
								<p className='sub-t-about-us'>Arabian Greens Supermarket is a part of Arabian Greens
									International (AGI), a leading general trading company
									based in the UAE. We offer a wide range of products and
									services across various industries worldwide, from food
									products to home appliances, stationery, and more. Our
									commitment to excellence and quality drives us to
									continuously improve our processes to ensure customer
									satisfaction.
								</p>
								<div className="border-dv"></div>
								<Link to='/' className='btn-home-explore'>More about us</Link>
								<Link to='/' className='btn-home-download-a'>Download Brochure</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="why-shop">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="shop-main">
								<h1 className='shop-with-txt'>Why Shop With Us?</h1>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="bg-shop" data-aos="zoom-in-down">
								<div className="shop-half">
									<img src={qul} className='shop-img' alt="" />
									<h4 className='shop-title'>Unmatched Quality</h4>
									<p className='shop-sub-title'>We believe in delivering only the
										best. From groceries to electronics,
										we maintain the highest standards.</p>
								</div>
								<div className="shop-half">
									<img src={leave} className='shop-img' alt="" />
									<h4 className='shop-title'>Diverse Product Range</h4>
									<p className='shop-sub-title'>A one-stop shop for all your
										needs—whether it's groceries, home
										essentials, or cosmetics</p>
								</div>
								<div className="shop-half">
									<img src={love} className='shop-img' alt="" />
									<h4 className='shop-title'>Customer-Centric Approach</h4>
									<p className='shop-sub-title'>Your satisfaction is our priority. We
										go above and beyond to ensure a
										hassle-free shopping experience.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="values">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="values-sub" data-aos="fade-up" data-aos-duration="1500">
								<h1 className='our-values'>Our Values</h1>
								<h3 className='our-values-title'>Sustainability</h3>
								<p className='our-values-sub'>To offer the finest quality products with exceptional
									customer service, ensuring that our customers’ needs are
									met with care and dedication.</p>
								<h3 className='our-values-title'>Innovation</h3>
								<p className='our-values-sub'>To be a trusted name in retail, providing a diverse range
									of products and services that bring convenience and
									satisfaction to our customers.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="values-sub-img" data-aos="fade-up" data-aos-duration="1500">
								<img src={val} className='value-img' alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="what-we-offer">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<h1 className='wh'>What We Offer</h1>
							<div className="offer-main">
								<Slider  {...settingsClients}>
									<div className="offer-slider">
										<img src={offer} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>Fresh Groceries									</h3>
											<p className='item-subb'>Locally sourced fruits, vegetables,
												dairy, and more</p>
											<Link to='/' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer3} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>Fresh Groceries									</h3>
											<p className='item-subb'>Locally sourced fruits, vegetables,
												dairy, and more</p>
											<Link to='/' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer2} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>Fresh Groceries									</h3>
											<p className='item-subb'>Locally sourced fruits, vegetables,
												dairy, and more</p>
											<Link to='/' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>Fresh Groceries									</h3>
											<p className='item-subb'>Locally sourced fruits, vegetables,
												dairy, and more</p>
											<Link to='/' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="exclusive">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="exclusive-box">
								<div className="exclusv-half">
									<h2 className='exclusive-text'>Exclusive</h2>
									<p className='exclusive-sub-text'>Get exclusive access to special deals, early product launches, and rewards every time you
										shop with us. Join our loyalty program today and start saving on your favorite products!</p>
								</div>
								<div className="exclusv-icon">
									<img src={ai} className='img-ai' alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>























		</div>
	)
}

export default Home