import React from 'react'
import './Outlet.css';
import logofooter from '../Assets/logo-white.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer-main'>
      <div className="container-main">
        <div className="row">
          <div className="col-lg-3">
            <div className="footer-inner">
              <img src={logofooter} className='footer-logo' alt="" />
              <p className='footer-sub-text'>Our Mission is to provide quality goods and services at competitive rates thereby ensuring customer satisfaction and wide clientele.</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-inner">
              <h4 className='title-footer'>Quick Links</h4>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/about'>About Us</Link></li>
                <li><Link to='/products'>Products</Link></li>
                <li><Link to='/services'>Services</Link></li>
                <li><Link to='/contact'>Contact Us</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-inner">
              <h4 className='title-footer'>Find Us On</h4>
              <div className="social-media">
                <div className="details">
                  <p className='icon-footer'><i class="ri-phone-fill"></i> +971 26731600</p>
                  <p className='icon-footer'><i class="ri-mail-unread-line"></i> info@agi.ae</p>
                  <p className='icon-footer'><i class="ri-map-pin-fill"></i> PO Box 32628, Al Mafraq, Abudhabi, UAE</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-inner">
              <h4 className='title-footer'>Social Media</h4>
              <div className="social-media">
                <i class="ri-instagram-line"></i>
                <i class="ri-facebook-circle-line"></i>
                <i class="ri-google-fill"></i>
                <i class="ri-twitter-x-line"></i>
                <i class="ri-vimeo-fill"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="footer-copyright">
              <p>© 2019 All Right Reserved By Arabian Greens</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer